import { render, staticRenderFns } from "./index.vue?vue&type=template&id=11c8649f&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=11c8649f&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11c8649f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PrismicButtonLinkComponent: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Prismic/ButtonLinkComponent/index.vue').default,PrismicLinkComponent: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Prismic/LinkComponent/index.vue').default,ElementsActionText: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/ActionText/index.vue').default,ElementsButtonIconOnly: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/Button/IconOnly.vue').default,LayoutSpacingHorizontal: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Layout/Spacing/Horizontal.vue').default,LayoutSpacingVerticalFullScreen: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Layout/Spacing/VerticalFullScreen.vue').default})

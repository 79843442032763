import { render, staticRenderFns } from "./index.vue?vue&type=template&id=02f3cec6&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PrismicButtonLinkComponent: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Prismic/ButtonLinkComponent/index.vue').default,ElementsActionText: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/ActionText/index.vue').default,PrismicLinkComponent: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Prismic/LinkComponent/index.vue').default})
